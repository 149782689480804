import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/analytics"
import "firebase/compat/storage"

const app = firebase.initializeApp({
  apiKey: "AIzaSyBYWYBB46WxicWp42VjGVxE_5Itir8OOTo",
  authDomain: "alert-bf30f.firebaseapp.com",
  projectId: "alert-bf30f",
  storageBucket: "alert-bf30f.appspot.com",
  messagingSenderId: "595791541785",
  appId: "1:595791541785:web:d6bda887337a369ba11c88",
})

firebase.analytics()

export const auth = app.auth()
export const firestore = app.firestore()
export const firebaseInstance = firebase
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider
export const storage = firebase.storage()

export default app

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
